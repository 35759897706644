import React from "react";

const EmptyMessage = ({
  message = "No business available matching these criteria in your region right now. Please check after some time.",
}) => {
  return (
    <div className="empty_msg">
      <span>{message}</span>
    </div>
  );
};

export default EmptyMessage;
