import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessList } from "../../Redux/Action/BusinessAction/businesAction";
import debounce from "lodash/debounce";

import InfinitScroll from "react-infinite-scroll-component";
import GridCardWrapper from "../../Component/GridCardWrapper";
import Card from "../../Component/Card";
import { BUSINESS_LIST } from "../../Utils/constants";
import EmptyMessage from "../../Component/EmptyMessage";
import { BUSINESS_DETAILS } from "../../Routes/RoutesConstant";

const BusinessList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // const businessData = useSelector(state => state.businessData);
  // const loadingRequest = useSelector(state => state.loadingReducer);
  const skeletonLoad = useSelector(
    (state) => state?.loadingReducers?.skeletonLoader
  );
  const {
    latLng: { lat, lng },
  } = useSelector((state) => state.locationReducer);
  const PageSize = 20;

  const { stateList, selectedState } = useSelector(
    (state) => state.locationReducer
  );
  const currentState = stateList.find((s) => s.state_code === selectedState);

  let [responseData, setResponseData] = useState([]);
  let [isNext, isNextFunc] = useState(false);
  let [pageCount, setCount] = useState(1);

  const [search, setSearch] = useState("");

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    fetchData(search);
  }, [pageCount, currentState]);

  useEffect(() => {
    setCount(1);
  }, [lat, lng]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  useEffect(() => {
    debouncVersion(search, 1);
  }, [search]);

  const businessPage = (id) => {
    navigate(BUSINESS_DETAILS, { state: { id } });
  };

  const fetchData = async (search = "", page = pageCount) => {
    let body = {
      user_uuid: "",
      fetchtype: "seeall",
      page,
      limit: PageSize,
      state: currentState?.state || "",
      state_code: currentState?.state_code || "",
      lat,
      lng,
      text: search,
    };
    let response = await dispatch(getBusinessList(body, location.state.type));
    if (response?.payload?.BusinessList) {
      if (page === 1) {
        setResponseData([...response.payload.BusinessList]);
      } else {
        setResponseData([...responseData, ...response.payload.BusinessList]);
      }
    }
    const totalPageCount = Math.ceil(
      response?.payload?.TotalRecords / PageSize
    );
    isNextFunc(pageCount !== totalPageCount);
  };

  const fetchMoreData = () => {
    setCount(pageCount + 1);
  };

  const debouncVersion = useCallback(debounce(fetchData), [
    lat,
    lng,
    currentState,
  ]);
  return (
    <>
      <div>
        <section className="green-heading">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>{location.state.type}</h2>
                <p>Bridging the gap between local businesses and customers.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="nib-boxes">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </form>
              </div>

              {!skeletonLoad[BUSINESS_LIST] && responseData.length === 0 ? (
                <EmptyMessage
                  message={`No Business found for the search ${search}`}
                />
              ) : (
                <InfinitScroll
                  dataLength={responseData.length}
                  next={fetchMoreData}
                  hasMore={isNext}
                >
                  <GridCardWrapper
                    blockWidth={335}
                    blockHeight={400}
                    isLoading={skeletonLoad[BUSINESS_LIST]}
                    data={responseData}
                  >
                    {(props) => (
                      <Card
                        gridView
                        showReview
                        showAddress
                        showPhone
                        handleViewDetail={businessPage}
                        {...props}
                        width={320}
                        height={380}
                      />
                    )}
                  </GridCardWrapper>
                </InfinitScroll>
              )}
            </div>
          </div>
          <div className="for-border" />
        </section>
      </div>
    </>
  );
};

export default BusinessList;
