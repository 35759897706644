import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getBusinessDetail,
  getBusinessEvent,
} from "../../Redux/Action/BusinessAction/businesAction";
import { isArray, isEmpty } from "lodash";
import moment from "moment";
import { formatPhone } from "../../Utils/localStroge";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";
import { getAnnouncements, getSales } from "../../API/ApiService";
import Linkify from "react-linkify";
import { skeletonAction } from "../../Redux/Action/loadingAction";
import { BUSINESS_SEARCH } from "../../Utils/constants";

const BusinessDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isbusiness, setBusiness] = useState({});
  const [event, setEvent] = useState({});
  const [announcements, setAnnouncements] = useState([]);
  const [sales, setSales] = useState([]);
  const [isBusinessHours, setBusinessHours] = useState({});
  const [visible, setVisible] = useState(4);
  console.log(isbusiness, "ib");
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  useEffect(async () => {
    let body;
    if (location?.state?.id) {
      body = { business_id: location?.state?.id };
    } else if (id) {
      body = { business_id: id };
    }
    const response = await dispatch(getBusinessDetail(body));

    if (response?.type === "GET_BUSINESS_DETAILS_SUCCESS") {
      setBusiness(response?.payload);
      setBusinessHours(response?.payload?.businessHours[0]);
    }

    const res = await dispatch(getBusinessEvent(location?.state?.id));

    if (res?.type === "GET_BUSINESS_EVENT_SUCCESS") {
      setEvent(res.payload);
    }

    getAnnouncements(location?.state?.id).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        setAnnouncements(res.data?.announcements || []);
      }
    });
    getSales(location?.state?.id).then((res) => {
      // console.log(res);
      if (res.status === 200) {
        setSales(res.data?.sales || []);
      }
    });
  }, []);

  const salesContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
    borderRadius: "5px",
    marginBottom: "10px",
    paddingTop: "10px",
  };
  const renderSlider = () => {
    if (isbusiness?.free_image) {
      return (
        <div className='barber-slider'>
          <div id='demo' className='carousel slide' data-bs-ride='carousel'>
            <div className='carousel-indicators'>
              <button
                type='button'
                data-bs-target='#demo'
                data-bs-slide-to={0}
                className='active'
              />
            </div>
            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img
                  src={isbusiness?.free_image}
                  alt='Los Angeles'
                  className='d-block'
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='barber-slider'>
          <div id='demo' className='carousel slide' data-bs-ride='carousel'>
            {/* Indicators/dots */}
            <div className='carousel-indicators'>
              {isbusiness?.businessImage?.length > 0 &&
                isbusiness?.businessImage.map((item, index) => {
                  return (
                    <button
                      type='button'
                      data-bs-target='#demo'
                      data-bs-slide-to={index}
                      className={index == 0 ? "active" : ""}
                      key={item?.id}
                    />
                  );
                })}
            </div>
            {/* The slideshow/carousel */}
            <div className='carousel-inner'>
              {isbusiness?.businessImage?.length > 0 &&
                isbusiness?.businessImage.map((item, index) => {
                  return (
                    <div
                      className={`carousel-item ${index == 0 ? `active` : ``}`}
                      key={item?.id}
                    >
                      <img
                        src={item.image}
                        alt='Los Angeles'
                        className='d-block'
                        style={{ width: "100%" }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderReview = () => {
    return isbusiness?.userReview?.length > 0 ? (
      isbusiness?.userReview.slice(0, visible).map((item, index) => {
        return (
          <div className='br-reviews' key={index}>
            <div className='row'>
              <div className='col-md-2'>
                <div className='review-img'>
                  <img
                    src={
                      item?.userProfile[0]?.profile_image &&
                      item?.userProfile[0]?.profile_image !== "N/A"
                        ? item?.userProfile[0]?.profile_image
                        : "../images/user.svg"
                    }
                    className='img-fluid'
                  />
                </div>
              </div>
              <div className='col-md-10'>
                <div className='review-text'>
                  <ul>
                    <li>{moment(item?.created_at).format("ll")}</li>
                    {/* <li>
                        <i className="fa-solid fa-star" /> {item.rating}
                      </li> */}
                  </ul>
                  <h4>
                    {item?.userProfile[0]?.name}{" "}
                    {Array.from(Array(parseInt(item.rating)), (e, i) => {
                      return (
                        <i
                          className='fa-solid fa-star'
                          style={{ color: "#2d8646" }}
                        />
                      );
                    })}
                  </h4>
                  {/* <span>United State</span> */}
                  <p>{item.comment}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <p>No Reviews</p>
    );
  };

  const renderBusinessHours = () => {
    const isDataCorrect = isArray(JSON.parse(isBusinessHours?.day_hours));
    const day_hours_data = JSON.parse(isBusinessHours?.day_hours);
    const custom_data = JSON.parse(isBusinessHours?.custom_hours);
    if (custom_data) {
      var dt = new Date();

      var firstDay = dt.getDate() - dt.getDay();
      var lastDay = dt.getDate() + 6 - dt.getDay();

      custom_data.map((itm, idx) => {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        var day = new Date(itm.day);
        var date = day.getDate();
        if (date >= firstDay && date <= lastDay) {
          var week = weekday[day.getDay()];
          custom_data[idx].day = week;
          var dayidx = weekday.indexOf(week);
          day_hours_data[dayidx - 1] = custom_data[idx];
        }
      });
    }

    return (
      isDataCorrect &&
      day_hours_data.map((item, index) => {
        const { day, hours } = item;

        return (
          <>
            {item?.toggle != false && (
              <tr key={index}>
                <td>{day}</td>
                <td>
                  {hours.map((data, key) => {
                    return (
                      <div key={key}>
                        <span>
                          {data?.open_hour} to {data?.close_hour}
                        </span>
                        <br />
                      </div>
                    );
                  })}
                </td>
              </tr>
            )}
          </>
        );
      })
    );
  };

  const renderEvent = () => {
    return !isEmpty(event.events) ? (
      event?.events?.map((item, index) => {
        const {
          id,
          title,
          address,
          event_date,
          event_time,
          eventImage,
          state,
          status,
          event_url,
        } = item;
        return (
          <>
            {status == 1 && (
              <div className='col-md-6' key={id}>
                <div className='event-box'>
                  <div className='event-box-img'>
                    <img
                      src={
                        eventImage[0]?.image
                          ? eventImage[0]?.image
                          : "../images/op1.jpg"
                      }
                      className='img-fluid'
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <h4>{title}</h4>
                  <p>{address}</p>
                  <p>
                    Date &amp; Timings: {event_date}, {event_time}
                  </p>
                  <a
                    href={event_url}
                    target='_blank'
                    style={{ color: "#3ca059" }}
                  >
                    {event_url}
                  </a>
                </div>
              </div>
            )}
          </>
        );
      })
    ) : (
      <p>No Events</p>
    );
  };

  const renderAnnouncements = () => {
    function urlify(message) {
      if (!message) return;
      const newText = message
        .split("\n")
        .map((str, idx) => <h4 key={idx}>{str}</h4>);
      return newText;
    }
    return announcements?.length > 0 ? (
      announcements?.map((item, index) => {
        const { id, description, image } = item;

        return (
          <div className='col-md-6 text-center' key={id}>
            <div className='event-box' style={{ height: "100%" }}>
              <div className='event-box-img' style={{ height: "80%" }}>
                <img
                  src={
                    image ||
                    "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
                  }
                  className='img-fluid'
                  style={{
                    // objectFit: "cover",
                    height: "300px",
                    width: "auto",
                  }}
                  alt=''
                />
              </div>
              <Linkify>{urlify(description)}</Linkify>
            </div>
          </div>
        );
      })
    ) : (
      <p>No Announcements</p>
    );
  };

  const renderSales = () => {
    return sales?.length > 0 ? (
      sales?.map((item, index) => {
        const { id, title, saleImage } = item;
        return (
          <div className='row' key={id}>
            <div className='col-md-6' style={salesContainerStyle}>
              {/* <div className='row'> */}
              <div className='event-box-img'>
                <img
                  src={
                    saleImage && saleImage.length > 0
                      ? saleImage[0].image
                      : "https://bgz-business.s3.us-east-2.amazonaws.com/FreeBusinessImage.jpg"
                  }
                  className='img-fluid'
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <h5 style={{ color: "green" }}>{title}</h5>
              {item.description && (
                <h6 style={{ color: "green" }}>Sale Details</h6>
              )}
              <ol style={{ listStyle: "outside", paddingLeft: "20px" }}>
                {item.description &&
                  item.description.map((val) => (
                    <li style={{ color: "#B0B0B0" }} key={val.id}>
                      {val.value}
                    </li>
                  ))}
              </ol>
              {item.term_conditions && (
                <h6 style={{ color: "green" }}>Sale Terms & Conditions </h6>
              )}
              <ol style={{ listStyle: "outside", paddingLeft: "20px" }}>
                {item.term_conditions.length > 0 &&
                  item.term_conditions.map((val) => (
                    <li style={{ color: "#B0B0B0" }}>{val.value}</li>
                  ))}
              </ol>
            </div>
          </div>
        );
      })
    ) : (
      <p>No Sales</p>
    );
  };
  return (
    <>
      <section className='vd-content pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='vd-content-text'>
                <h3>{isbusiness?.business_name}</h3>
                <p
                  style={{
                    color: "#ffffffc9",
                    fontSize: "14px",
                    fontWeight: 300,
                    textAlign: "initial",
                  }}
                >
                  {/* {address1} */}
                  {isbusiness?.tag_line}
                </p>
                <ul>
                  {isbusiness?.reviewAverage != undefined &&
                    Array.from(
                      Array(parseInt(isbusiness?.reviewAverage)),
                      (e, i) => {
                        return (
                          <li key={i}>
                            <i className='fa-solid fa-star' />
                          </li>
                        );
                      }
                    )}
                  {Array.from(
                    { length: 5 - parseInt(isbusiness?.reviewAverage || 0) },
                    (e, j) => {
                      return (
                        <li key={j + 5}>
                          <i
                            style={{ marginRight: "3px" }}
                            className='fa-regular fa-star'
                          />
                        </li>
                      );
                    }
                  )}
                </ul>
                <span>({isbusiness?.reviewCount} Reviews)</span>
                {renderSlider()}
                <p>{isbusiness?.description}</p>
                <div className='barber-accordion'>
                  <div className='accordion' id='accordionExample'>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingOne'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseOne'
                          aria-expanded='false'
                          aria-controls='collapseOne'
                        >
                          Reviews
                        </button>
                      </h2>
                      <div
                        id='collapseOne'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingOne'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          {renderReview()}
                          {isbusiness?.userReview?.length >= visible && (
                            <button onClick={showMoreItems}>
                              <img src='../images/angle-down.png' /> See more
                              reviews
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingTwo'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseTwo'
                          aria-expanded='false'
                          aria-controls='collapseTwo'
                        >
                          Events
                        </button>
                      </h2>
                      <div
                        id='collapseTwo'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingTwo'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          <div className='row'>{renderEvent()}</div>
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingThree'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseThree'
                          aria-expanded='false'
                          aria-controls='collapseThree'
                        >
                          Announcements
                        </button>
                      </h2>
                      <div
                        id='collapseThree'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingThree'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          <div className='row'>{renderAnnouncements()}</div>
                        </div>
                      </div>
                    </div>
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='headingFour'>
                        <button
                          className='accordion-button collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#collapseFour'
                          aria-expanded='false'
                          aria-controls='collapseFour'
                        >
                          Sales
                        </button>
                      </h2>
                      <div
                        id='collapseFour'
                        className='accordion-collapse collapse'
                        aria-labelledby='headingFour'
                        data-bs-parent='#accordionExample'
                      >
                        <div className='accordion-body'>
                          <div className='row'>{renderSales()}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='grab-container'>
                  {/* <div className="grab-boxes">
                    <div className="row">
                      <div className="col-md-6 grab-box1">
                        <div className="grab-box-img">
                          <img
                            src="images/offercard34.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 grab-box1">
                        <div className="grab-box-img">
                          <img
                            src="images/offercard34.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='dwnld-app'>
                    <h5>
                      Download the{" "}
                      <font style={{ color: "#349C52" }}>Brigiz</font> App now
                    </h5>
                    <div className='download-btns'>
                      <button
                        onClick={() => window.open(ANDROID_APP_LINK, "_")}
                      >
                        <div className='row'>
                          <div className='col-3 btn-img'>
                            <img src='../images/play-button.svg' />
                          </div>
                          <div className='col-9 btn-txt'>
                            <span>GET IT ON</span>
                            <p>Google Play</p>
                          </div>
                        </div>
                      </button>
                      <button onClick={() => window.open(IOS_APP_LINK, "_")}>
                        <div className='row'>
                          <div className='col-3 btn-img'>
                            <img src='../images/apple.svg' />
                          </div>
                          <div className='col-9 btn-txt'>
                            <span>Download on the</span>
                            <p>App Store</p>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-4' style={{ paddingTop: "125px" }}>
              <div className='vd-content-details'>
                <div className='contact-info'>
                  <h4>Contact Information</h4>
                  <ul className='address-info'>
                    {isbusiness?.phone && (
                      <li>
                        <a
                          href={`tel:${isbusiness?.country_code}${isbusiness?.phone}`}
                        >
                          <img src='../images/call-calling.svg' />{" "}
                          {formatPhone(
                            `${isbusiness?.country_code}${isbusiness?.phone}`
                          )}
                        </a>
                      </li>
                    )}
                    {/* {isbusiness?.users?.email_id && (
                      <li>
                        <a
                          href={`mailto:${isbusiness?.users?.email_id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src="images/sms.svg" />{" "}
                          {isbusiness?.users?.email_id}
                        </a>
                      </li>
                    )} */}
                    {isbusiness?.website && (
                      <li>
                        <a
                          href={
                            isbusiness?.website?.includes("http")
                              ? isbusiness?.website
                              : `https://${isbusiness?.website}`
                          }
                          target='_blank'
                        >
                          <img src='../images/global.svg' />
                          {isbusiness?.website}
                        </a>
                      </li>
                    )}
                    {isbusiness?.is_address_hide === 1 && (
                      <>
                        <li className='add-green'>
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${isbusiness?.lat},${isbusiness?.lng}`}
                            target='_blank'
                          >
                            <img src='../images/map.svg' />{" "}
                            {isbusiness?.address}
                          </a>
                        </li>
                      </>
                    )}
                    {isbusiness?.business_email && (
                      <li>
                        <a style={{ color: "#fff" }}>
                          <img src='../images/sms.svg' />
                          {isbusiness?.business_email}
                        </a>
                      </li>
                    )}
                    {isbusiness?.alternative_website && (
                      <li>
                        <a style={{ color: "#fff" }}>
                          <img src='../images/global.svg' />
                          {isbusiness?.alternative_website}
                        </a>
                      </li>
                    )}
                  </ul>
                  <ul className='vd-socials'>
                    {isbusiness?.facebook && (
                      <li>
                        <a href={`//${isbusiness?.facebook}`} target='_blank'>
                          <img src='../images/fb2.svg' />
                        </a>
                      </li>
                    )}
                    {isbusiness?.twitter && (
                      <li>
                        <a href={`//${isbusiness?.twitter}`} target='_blank'>
                          <img src='../images/twitter2.svg' />
                        </a>
                      </li>
                    )}
                    {isbusiness?.instagram && (
                      <li>
                        <a href={`//${isbusiness?.instagram}`} target='_blank'>
                          <img src='../images/instagram12.svg' />
                        </a>
                      </li>
                    )}

                    {isbusiness?.youtube && (
                      <li>
                        <a
                          href={`//${isbusiness?.youtube}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <img
                            src='../images/youtube_logo.png'
                            style={{
                              background: "#49ab59",
                              borderRadius: "50%",
                            }}
                          />
                        </a>
                      </li>
                    )}
                    {isbusiness?.linkedIn && (
                      <li>
                        <a href={`//${isbusiness?.linkedIn}`} target='_blank'>
                          <img
                            src='../images/linkedin2.svg'
                            style={{ width: "30px" }}
                          />
                        </a>
                      </li>
                    )}
                    {isbusiness?.ticktock && (
                      <li>
                        <a href={`//${isbusiness?.ticktock}`} target='_blank'>
                          <img src='../images/ticktock.svg' />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                {!isEmpty(isBusinessHours) &&
                  isArray(JSON.parse(isBusinessHours?.day_hours)) && (
                    <div className='opening-hrs'>
                      <h4>Opening Hours</h4>
                      <table>
                        <tbody>{renderBusinessHours()}</tbody>
                      </table>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessDetails;
