export const setItemLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
  return key;
};
export const getItemLocalStorage = (key) => {
  let token = key ? localStorage.getItem(key) : "";
  return token;
};

export const formatPhone = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return cleaned;
};
