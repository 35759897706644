import React, { useEffect, useState } from "react";
import { getAllCMSData } from "../../API/ApiService";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../Redux/Action/loadingAction";

const Term = () => {
  const [data, setData] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    getCMSData();
  }, []);
  
  const getCMSData = () => {
    dispatch(loadingAction(true));
    getAllCMSData()
      .then((res) => {
        setData(res?.data?.result?.[2]?.content || "");
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        dispatch(loadingAction(false));
      });
  };
  return (
    <>
      <section class="privacy-txt">
        <div class="container">
          <div class="row">
            <div class="col-md-12 wow fadeIn">
              <h3>BRIGIZ MOBILE APPLICATION END USER LICENSE AGREEMENT</h3>
              {/* <span>Last updated on February 13, 2022</span> */}
              <hr />
              {parse(data)}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Term;
