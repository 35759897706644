import { useEffect } from "react";
// import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { ALLREVIEWS, LOGIN } from "./RoutesConstant";
import { useSelector } from "react-redux";

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();
  const { setShow } = useSelector((state) => state.locationReducer);
  // const showState = locationData.setShow;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return (
    <>
      {!pathname.includes(LOGIN) ? <Header showState={setShow} loginShow={true}/> : <Header showState={false} loginShow={false}/>}
        <div className={!pathname.includes(LOGIN) && "body_wrapper"}>
          {children || null}
          {pathname !== ALLREVIEWS && !pathname.includes(LOGIN) && <Footer />}
        </div>
    </>
  );
};

export default ScrollToTop;
