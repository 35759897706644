export const LOADING = "LOADING";
export const SKELETON_LOADER = "SKELETON_LOADER";
export const GET_BUSINESS_FEATURE_SUCCESS = "GET_BUSINESS_FEATURE_SUCCESS";
export const GET_BUSINESS_FEATURE_ERROR = "GET_BUSINESS_FEATURE_ERROR";
export const GET_POPULAR_CAT_SUCCESS = "GET_POPULAR_CAT_SUCCESS";
export const GET_POPULAR_CAT_ERROR = "GET_POPULAR_CAT_ERROR";
export const GET_BUSINESS_DETAILS_SUCCESS = "GET_BUSINESS_DETAILS_SUCCESS";
export const GET_BUSINESS_DETAILS_ERROR = "GET_BUSINESS_DETAILS_ERROR";
export const GET_LOCAL_HIGHTLIGHT_SUCCESS = "GET_LOCAL_HIGHTLIGHT_SUCCESS";
export const GET_LOCAL_HIGHTLIGHT_ERROR = "GET_LOCAL_HIGHTLIGHT_ERROR";
export const GET_CONTACTUS_SUCCESS = "GET_CONTACTUS_SUCCESS";
export const GET_CONTACTUS_ERROR = "GET_CONTACTUS_ERROR";
export const GET_BUSINESS_EVENT_SUCCESS = "GET_BUSINESS_EVENT_SUCCESS";
export const GET_BUSINESS_EVENT_ERROR = "GET_BUSINESS_EVENT_ERROR";
export const GET_SUBSCRIBE_SUCCESS = "GET_SUBSCRIBE_SUCCESS";
export const GET_SUBSCRIBE_ERROR = "GET_SUBSCRIBE_ERROR";
export const GET_PLAN_LIST_SUCCESS = "GET_PLAN_LIST_SUCCESS";
export const GET_PLAN_LIST_ERROR = "GET_PLAN_LIST_ERROR";
export const GET_PLAN_BYID_SUCCESS = "GET_PLAN_BYID_SUCCESS";
export const GET_PLAN_BYID_ERROR = "GET_PLAN_BYID_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const BUSINESS_LIST_SUCCESS = "BUSINESS_LIST_SUCCESS";
export const BUSINESS_LIST_ERROR = "BUSINESS_LIST_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOGOUT_DETAILS_SUCCESS = "LOGOUT_DETAILS_SUCCESS";
export const BUSINESS_TYPE_SUCCESS = "BUSINESS_TYPE_SUCCESS";
export const BUSINESS_TYPE_ERROR = "BUSINESS_TYPE_ERROR";
export const SEARCH_BUSINESS_SUCCESS = "SEARCH_BUSINESS_SUCCESS";
export const SEARCH_BUSINESS_ERROR = "SEARCH_BUSINESS_ERROR";
export const LOCATION_LIST_SUCCESS = "LOCATION_LIST_SUCCESS";
export const LOCATION_LIST_ERROR = "LOCATION_LIST_ERROR";
export const SELECT_LOCATION = "SELECT_LOCATION";
export const SET_LAT_LNG = "SET_LAT_LNG";
