import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadingAction } from "../Redux/Action/loadingAction";
import { useDispatch } from "react-redux";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    dispatch(loadingAction(true));
    // try {
    //   const { error, paymentIntent } = await stripe.confirmPayment({
    //     //`Elements` instance that was used to create the Payment Element
    //     elements,
    //     confirmParams: {
    //       return_url: `${window.location.origin}/successfullPayment`,
    //     },
    //   });

    //   if (error) {
    //     // This point will only be reached if there is an immediate error when
    //     // confirming the payment. Show error to your customer (for example, payment
    //     // details incomplete)
    //     setErrorMessage(error.message);
    //     dispatch(loadingAction(false));
    //     console.log(error, "err");
    //   } else if (paymentIntent) {
    //     // setPaymentStatus('success'); // Handle payment success

    //     console.log("Payment successful!");
    //   } else {
    //     console.log("11111111");
    //     // Your customer will be redirected to your `return_url`. For some payment
    //     // methods like iDEAL, your customer will be redirected to an intermediate
    //     // site first to authorize the payment, then redirected to the `return_url`.
    //   }
    const error = stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
          // return_url: 'https://example.com',
          return_url: `${window.location.origin}/successfullPayment`,
        },
        redirect: "if_required",
      })
      .then((result) => {
        if (result?.paymentIntent?.status === "succeeded") {
          // console.log(result?.paymentIntent);
          window.location.replace(
            `${window.location.origin}/successfullPayment`
          );
        } else {
          // console.log(result?.paymentIntent);
          window.location.replace(`${window.location.origin}/failedPayment`);
        }
        // console.log("RINKI ============> ", result.paymentIntent);
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        setErrorMessage(err.message);
        dispatch(loadingAction(false));
        console.log(err, "err");
        window.location.replace(`${window.location.origin}/failedPayment`);
      });
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      dispatch(loadingAction(false));
      console.log(error, "err");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <span
        style={{
          fontSize: "25px",
          padding: "0px 7px",
          borderRadius: "50%",
          position: "absolute",
          right: "5px",
          top: "0px",
          cursor: "pointer",
        }}
        onClick={() => props.onRequestClose()}
      >
        &times;
      </span>
      <div
        style={{
          fontSize: "18px",
          fontWeight: "600",
          margin: "10px 0",
          textAlign: "center",
          border: "1px solid #dbdbdb",
          padding: "8px",
          borderRadius: "5px",
          background: "#f2f2f2",
          marginBottom: "1rem",
        }}
      >
        Checkout
      </div>
      <PaymentElement />
      <button
        style={{ marginTop: "1rem", fontWeight: "600" }}
        className='pln-next-btn'
        type='submit'
        disabled={!stripe}
      >
        Pay ${props.amount}
      </button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
