import {
  DAILY_DEAL,
  FEATURE_BUSINESS,
  LOCAL_HIGHLIGHT,
  NEW_BUSINESS,
  BUSINESS_SEARCH
} from "../../Utils/constants";
import { LOADING, SKELETON_LOADER } from "../Action/actionTypes";
const iState = {
  loading: false,
  skeletonLoader: {
    [NEW_BUSINESS]: false,
    [FEATURE_BUSINESS]: false,
    [DAILY_DEAL]: false,
    [LOCAL_HIGHLIGHT]: true,
    [BUSINESS_SEARCH]: false
  },
};
const loadingReducer = (state = iState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.status };
    case SKELETON_LOADER:
      return {
        ...state,
        skeletonLoader: {
          ...state.skeletonLoader,
          [action.id]: action.status,
        },
      };
    default:
      return state;
  }
};

export default loadingReducer;
