import React, { useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";

const SkeletonLoader = ({ height = 380, width = 320, count = 1, ...props }) => {
  function InlineWrapperWithMargin({ children }) {
    return (
      <span
        style={{ display: "flex", alignItems: "center"}}
      >
        {children}
      </span>
    );
  }
  return (
    <SkeletonTheme
      baseColor="#202020"
      highlightColor="#444"
      borderRadius="0.5rem"
    >
      <Skeleton
        height={height}
        width={width}
        count={count}
        wrapper={InlineWrapperWithMargin}
      />
    </SkeletonTheme>
  );
};

export default SkeletonLoader;
