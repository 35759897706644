export const checkForSameLocation = (lat1, lng1, lat2, lng2) => {
  const earthRadius = 3958.75; // in miles, change to 6371 for kilometer output

  function toRad(Value) {
    /** Converts numeric degrees to radians */
    return Value * Math.PI / 180;
}

  const dLat = toRad(lat2 - lat1);
  const dLng = toRad(lng2 - lng1);

  const sindLat = Math.sin(dLat / 2);
  const sindLng = Math.sin(dLng / 2);

  const a =
    Math.pow(sindLat, 2) +
    Math.pow(sindLng, 2) *
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2));

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const dist = earthRadius * c;

  return dist < 0.5; // output distance, in MILES
};