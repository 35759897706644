import React from "react";
import { useSelector } from "react-redux";
import SelectBox from "../select-box/index";
import "./index.css";

const StateDropdown = ({ currentState, onChange, setStatePrice }) => {
  const { stateList } = useSelector((state) => state.locationReducer);
  return (
    <div className="location-block">
      <i
        className="fa-solid fa-location-dot locationIcon"
        style={{ color: "#349c52", marginRight: "5px", fontSize: "15px" }}
      />
      <SelectBox
        options={stateList.map((s, i) => (
          <div key={i} className="home_select_option" id={s.state_code} onClick={() => setStatePrice(s.plan_price)}>
            {s.state}

          </div>
        ))}
        placeholder="Select State"
        selectedValue={currentState?.state}
        onChange={onChange}
      />
    </div>
  );
};

export default StateDropdown;
