import {GET_CONTACTUS_SUCCESS,GET_CONTACTUS_ERROR,
  GET_SUBSCRIBE_SUCCESS,GET_SUBSCRIBE_ERROR} from "../actionTypes";
import {contactusApi,subscribeApi} from "../../../API/ApiService";
import { loadingAction } from "../loadingAction";


  export const submitContactUs = (data) => {
    return dispatch => {
      dispatch(loadingAction(true));
      return contactusApi(data)
        .then(response => {
          if (response && response.status === 200) {
            dispatch(loadingAction(false));           
            return   dispatch({ type: GET_CONTACTUS_SUCCESS, payload: response });
          }
        })
        .catch(({ ...error }) => {
            dispatch(loadingAction(false));
        return   dispatch({ type: GET_CONTACTUS_ERROR, payload: error.response });
           
        //   throw error;
        });
    };
  };

  export const submitSubcriber = (data) => {
    return dispatch => {
      dispatch(loadingAction(true));
      return subscribeApi(data)
        .then(response => {
          if (response && response.status === 200) {
            dispatch(loadingAction(false));           
            return   dispatch({ type: GET_SUBSCRIBE_SUCCESS, payload: response });
          }
        })
        .catch(({ ...error }) => {
            dispatch(loadingAction(false));
        return   dispatch({ type: GET_SUBSCRIBE_ERROR, payload: error.response });
           
        //   throw error;
        });
    };
  };



