import React, { useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import "./index.css";
// import DownArrow from "../../../images/down-arrow.svg";

const SelectBox = ({
  onChange,
  options,
  selectedValue,
  placeholder = "Select type",
  reset,
  setReset,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    selectedValue && setSelected(selectedValue);
  }, [selectedValue]);

  const handleChange = (option) => {
    setSelected(option.props.children);
  };
  return (
    <ClickAwayListener onClickAway={() => setIsActive(false)}>
      <div className="select_box">
        <div
          className={`selectbox_btn  selection-active`}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <span className="selected_title">
            { selected === "" 
              ? 
              <span style={{ color: "#878484" }}>{placeholder}</span>
              : selected
            }
          </span>
          <img src={process.env.PUBLIC_URL + "/images/down-arrow.svg"} />
        </div>
        {isActive && (
          <div className="selectbox_content">
            <div className={`selectbox_item `}>
              {options.length > 0 && options?.map((option, i) => {
                return (
                  <span
                    key={i}
                    className="select_items"
                    onClick={(e) => {
                      handleChange(option);
                      onChange(e);
                      setIsActive(false);
                      setReset(false)
                    }}
                  >
                    {option}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SelectBox;
