import {GET_PLAN_LIST_SUCCESS,GET_PLAN_LIST_ERROR} from "../actionTypes";
  import {getAllPlanApi} from "../../../API/ApiService";
  import { loadingAction } from "../loadingAction";
   
    export const getPlanList = () => {
      return dispatch => {
        dispatch(loadingAction(true));
        return getAllPlanApi()
          .then(response => {
            if (response && response.status === 200) {
              dispatch(loadingAction(false));           
              return   dispatch({ type: GET_PLAN_LIST_SUCCESS, payload: response.data.result1 });
            }
          })
          .catch(({ ...error }) => {
              dispatch(loadingAction(false));
          return   dispatch({ type: GET_PLAN_LIST_ERROR, payload: error.response });
             
          //   throw error;
          });
      };
    };
  