import React from "react";

const EspPolicy = () => {
  return (
    <>
      <section class="privacy-txt">
        <div class="container">
          <div class="row">
            <div class="col-md-12 wow fadeIn">
              <h3 style={{ textTransform: "uppercase" }}>
                Política de privacidad de Brigiz
              </h3>
              <hr />
              <p>
                Esta Política de Privacidad divulga las prácticas de privacidad
                de Brigiz, Inc. ("Brigiz") y los posibles usos de la información
                que recopilamos a través de http://www.Brigiz.com/, nuestra(s)
                aplicación(es) móvil(es), y a través de otras presentaciones
                realizadas por usted a Brigiz (el "Sitio Web"). Al utilizar el
                Sitio Web, usted consiente los términos de esta Política de
                Privacidad. Si no está de acuerdo con los términos y condiciones
                de esta Política de Privacidad, es posible que no pueda utilizar
                ciertas partes o funciones de nuestro Sitio Web, y en algunos
                casos, esto puede requerir la revocación de su membresía. Brigiz
                puede actualizar esta Política de Privacidad de vez en cuando a
                su entera discreción. Es su responsabilidad revisar la Política
                de Privacidad para cualquier cambio cada vez que utilice el
                Sitio Web y está obligado por dichos cambios. Su uso del Sitio
                Web después de que publiquemos una nueva política de privacidad
                en nuestro Sitio Web significa que acepta y está de acuerdo con
                la Política de Privacidad modificada. Esta Política de
                Privacidad se aplica a toda la familia de servicios de Brigiz,
                como nuestros servicios en línea, nuestro centro de soporte
                telefónico para clientes y otros servicios y aplicaciones de
                Brigiz. Esta Política de Privacidad es parte de nuestro Acuerdo
                de Membresía de Brigiz, Acuerdo de Negocios Locales, Términos de
                Uso y todos los demás términos de uso de nuestro Sitio Web.
              </p>
              <h3>Información Recopilada</h3>
              <p>
                Brigiz no recopila información personal sobre nuestros usuarios
                de las diversas formas que comúnmente lo hacen negocios
                similares. Brigiz no permite, ni facilita, que ningún socio
                tercero recopile y comparta su información personal; tampoco
                permite Brigiz que terceros rastreen sus actividades mientras
                usa nuestras Apps o el sitio web a través de cookies y otras
                tecnologías de seguimiento.
              </p>
              <h3>Información Personal</h3>
              <p>
                "Infromación Personal" se refiere a la información que nos dice
                específicamente quién es usted, como su nombre, número de
                teléfono, correo electrónico, dirección postal y posiblemente
                información relacionada con uno o más Negocios Locales
                específicos que pueda utilizar a través de nuestras Apps o sitio
                web. Brigiz no recopilará, almacenará ni compartirá ninguna de
                las informaciones personales descritas anteriormente sin su
                conocimiento o consentimiento.
              </p>
              <h3>Números de Teléfonos Celulares</h3>
              <p>
                Brigiz no utilizará, almacenará ni compartirá su número de
                teléfono celular con terceros en nuestro nombre, para entregar
                llamadas y/o mensajes de texto de telemarketing automarcados o
                pregrabados al número que proporcione.
              </p>
              <h3>Envío de Reseñas, Calificaciones y Comentarios</h3>
              <p>
                Recopilamos información que usted proporciona voluntariamente
                como parte del uso regular de nuestras Apps y Sitio Web,
                incluidos mensajes, publicaciones, comentarios, respuestas,
                reseñas y calificaciones que envía a través del Sitio Web
                ("Contenido Generado por el Usuario"). Brigiz puede, a su entera
                discreción, compartir su Contenido Generado por el Usuario con
                otros, como el Negocio Local que es objeto de sus reseñas. Si no
                desea que cierta información se comparta con otros, no debe
                enviar Contenido Generado por el Usuario o no debe enviar
                Contenido Generado por el Usuario que contenga información o
                Información Personal que no desea compartir de esta manera. Una
                vez que haya enviado Contenido Generado por el Usuario, nos
                reservamos el derecho de publicarlo en cualquier medio a otros.
              </p>
              <h3>Información Agregada</h3>
              <p>
                Podemos recopilar información estadística general, no personal,
                sobre el uso del Sitio Web, como cuántos visitantes visitan una
                página específica en el Sitio Web, cuánto tiempo permanecen en
                esa página y en qué hipervínculos, si los hay, hacen clic. Esta
                información representa una visión general genérica de nuestros
                usuarios, incluidos sus hábitos de visualización colectivos, y
                nos permite a nosotros y a otros terceros modificar la
                información, promociones, ofertas y/o descuentos en servicios
                basados en el tráfico y comportamiento del usuario. Recopilamos
                esta información mediante el uso de tecnologías como 'cookies' y
                balizas web, que se discuten con más detalle a continuación.
                Recopilamos esta información para ayudar a mejorar el Sitio Web
                para que la experiencia del visitante sea mejor.
              </p>
              <h3>Información de Terceros</h3>
              <p>
                Podemos complementar la información que usted nos proporciona
                con información de otras fuentes, como información para validar
                o actualizar su dirección, tarjeta de pago u otra información
                demográfica y de estilo de vida. Utilizamos esta información
                para ayudarnos a mantener la precisión de la información que
                recopilamos, para permitirnos servirle mejor, mejorar su
                experiencia de usuario y para análisis comerciales internos u
                otros fines comerciales consistentes con nuestra misión.
              </p>
              <h3>Cookies</h3>
              <p>
                Brigiz puede recopilar automáticamente información y datos no
                identificables personalmente mediante el uso de cookies. Las
                cookies son pequeños archivos de texto que un sitio web utiliza
                para reconocer a los usuarios recurrentes, facilitar el acceso
                continuo del usuario al sitio web y facilitar el uso del sitio
                web. Las cookies también permiten que un sitio web rastree el
                comportamiento de uso y compile información de uso del sitio que
                permitirá la mejora del contenido. La mayoría de los navegadores
                le permiten controlar las cookies, incluido si las acepta o no y
                cómo eliminarlas. Puede configurar sus navegadores para que le
                notifiquen si recibe una cookie o puede optar por bloquear las
                cookies con su navegador. Sin embargo, tenga en cuenta que
                algunas funciones de nuestro Sitio Web pueden no funcionar o
                pueden ser más lentas si rechaza las cookies.
              </p>
              <p>
                También puede recibir cookies de nuestros socios de Negocios
                Locales u otros terceros con enlaces en el Sitio Web, como se
                describe a continuación. No controlamos directamente estas
                cookies. El uso de cookies de publicidad enviadas por servidores
                de terceros es estándar en la industria de Internet.
              </p>
              <p>
                Brigiz y sus proveedores de servicios pueden utilizar cookies y
                otras tecnologías para recibir y almacenar ciertos tipos de
                información cada vez que interactúa con nosotros. Esta
                información nos permite reconocerlo, personalizar su experiencia
                en el sitio web y hacer que nuestros mensajes de marketing sean
                más relevantes.
              </p>
              <h3>Otras Tecnologías de Seguimiento</h3>
              <p>
                Las tecnologías de seguimiento pueden registrar información como
                nombres de dominio y host de Internet; direcciones de protocolo
                (IP); software del navegador y tipos de sistemas operativos;
                patrones de clics; la dirección MAC u otra información técnica
                de su dispositivo móvil; información de ubicación a través de
                GPS, WiFi, Bluetooth o tecnologías similares; y fechas y horas
                en que se accede a nuestro Sitio Web. Una dirección IP es un
                número único que se asigna automáticamente a su computadora cada
                vez que navega por la web. Los servidores web, las computadoras
                que "sirven" páginas web, identifican automáticamente su
                computadora por su dirección IP. El Sitio Web también puede
                utilizar una tecnología llamada "etiquetas de rastreo". Estas
                también pueden denominarse "Píxeles", "GIF Transparentes" o
                "Balizas Web". Esta tecnología nos permite comprender qué
                páginas visita en nuestro Sitio Web y otras formas en que
                interactúa con nuestro Sitio Web, como las compras realizadas a
                través del Sitio Web. Estas etiquetas de rastreo se utilizan
                para ayudarnos a optimizar y personalizar nuestro Sitio Web para
                usted y otros usuarios de nuestro Sitio Web.
              </p>
              <h3>No Rastrear (Do Not Track)</h3>
              <p>
                Do Not Track (DNT) es una preferencia de privacidad que los
                usuarios pueden configurar en algunos navegadores web, lo que
                permite a los usuarios optar por no ser rastreados por sitios
                web y servicios en línea. En la actualidad, el Consorcio World
                Wide Web (W3C) aún no ha establecido estándares universales para
                señales DNT reconocibles y, por lo tanto, Brigiz y el Sitio no
                reconocen DNT.u
              </p>
              <h3>Uso de la Información</h3>
              <p>
                La información que Brigiz recopila se utiliza de diversas
                maneras, incluyendo revisión interna; para mejorar el Sitio y
                los Servicios; para optimizar las ofertas de terceros de
                productos y/o servicios; para verificar la legitimidad de
                opiniones y calificaciones; para notificarle sobre
                actualizaciones del Sitio web; para informarle sobre productos,
                servicios y promociones que pueden interesarle; para obtener
                opiniones y calificaciones; para nuestros fines de marketing y
                el marketing de descuentos ofrecidos a través de nuestro Sitio y
                Servicios por contratistas de servicios o proveedores
                relacionados con la salud (colectivamente "Proveedores de
                Servicios"); para cumplir y proporcionar productos y servicios,
                incluidos los servicios personalizados o mejorados solicitados
                por usted; para análisis comerciales internos u otros fines
                comerciales consistentes con nuestra misión; y para llevar a
                cabo otros fines que se le revelen y a los que usted consienta.
              </p>
              <h3>Divulgaciones y Transferencias de Información</h3>
              <p>
                No divulgamos Información Personal a terceros, excepto cuando se
                cumpla una o más de las siguientes condiciones: tenemos su
                permiso para realizar la divulgación; la divulgación es
                necesaria para el propósito para el cual se obtuvo la
                información personal; la divulgación es al socio comercial local
                de quien compró servicios a través de la plataforma de Brigiz.
              </p>
              <p>
                La divulgación es a proveedores de servicios financieros para
                cumplir y llevar a cabo los servicios de membresía de compra
                solicitados por usted; la divulgación está permitida por la ley
                relevante; la Información Personal a divulgar está disponible
                públicamente de acuerdo con la ley aplicable; la divulgación
                está razonablemente relacionada con la venta u otra disposición
                de toda o parte de nuestro negocio o activos; la divulgación es
                para nuestros propios fines de marketing o, con su autorización,
                para los fines de marketing de terceros; la divulgación se
                combina con información recopilada de otras compañías y se
                utiliza para mejorar y personalizar servicios, contenido y
                publicidad de nosotros o de terceros; la parte a la que se
                realiza la divulgación controla, es controlada por, o está bajo
                control común con Brigiz; la divulgación es, a nuestra entera
                discreción, necesaria para el establecimiento o mantenimiento de
                reclamaciones legales o cumplimiento legal, para satisfacer
                cualquier ley, regulación, citación o solicitud gubernamental, o
                en relación con litigios; la divulgación es, a nuestra entera
                discreción, sobre usuarios que creemos están involucrados en
                actividades ilegales o que de otro modo están en violación de
                nuestro Acuerdo de Membresía de Brigiz, incluso sin una
                citación, orden judicial o mandato judicial; o la divulgación es
                a empresas externas para realizar ciertos servicios para
                nosotros, como mantener nuestro sitio web, listas de correo,
                enviar correo postal, procesar reclamaciones por certificados
                perdidos o robados, proporcionar asistencia de marketing,
                confirmar su identidad para la integridad de la revisión y
                análisis de datos ("Proveedores de Servicios Administrativos"),
                incluidos Proveedores de Servicios Administrativos fuera del
                país o jurisdicción en la que reside.
              </p>
              <p>
                Antes de compartir su Información Personal con otros, se hacen
                acuerdos entre Brigiz y dichas partes externas a las que
                transferimos datos para proporcionar el uso permitido y la
                seguridad de la información transferida de acuerdo con esta
                Política de Privacidad y la ley aplicable. Si Brigiz se entera
                de que alguna de esas partes externas está usando o divulgando
                de manera inapropiada Información Personal, notificará a esa
                parte externa y solicitará que tome medidas razonables para
                cesar dicho uso o divulgación.
              </p>
              <p>
                Podemos proporcionar cierta Información Personal como un
                servicio a otros para sus fines de marketing. También podemos
                divulgar su nombre y dirección a Proveedores de Servicios cuando
                envíe Contenido Generado por el Usuario relacionado con esos
                Proveedores de Servicios. Si nos notifica adecuadamente que ya
                no desea que compartamos su Información Personal, dejaremos de
                compartir su Información Personal. Sin embargo, excepto como se
                indica de otro modo en esta Política de Privacidad, no
                controlamos directamente a los terceros con los que compartimos
                dicha Información Personal y deberá notificar directamente a
                dichos terceros si desea que dejen de usar su Información
                Personal.
              </p>
              <p>
                También podemos divulgar datos agregados de visitantes para
                describir el uso del sitio web a nuestros socios comerciales
                existentes o potenciales u otros terceros, o en respuesta a una
                solicitud gubernamental. También podemos compartir datos
                demográficos y de preferencias agregados con terceros para
                permitir la provisión de información, promociones, ofertas y/o
                descuentos dirigidos en productos y servicios.
              </p>
              <h3>
                Elección: Acceso a Su Información y Solicitudes de
                Inclusión/Exclusión
              </h3>
              <p>
                Para ayudarle a revisar, editar y controlar cómo usamos gran
                parte de la información que recopilamos sobre usted, puede
                visitar su página web "Mis Cuentas". En la página web "Mis
                Cuentas", puede actualizar cierta información y darse de baja
                para no recibir tipos específicos de mensajes de correo
                electrónico y llamadas telefónicas. Sin embargo, no puede darse
                de baja para no recibir ciertos correos electrónicos, como
                correos electrónicos relacionados con el estado de la cuenta y
                la facturación, a menos que cierre su cuenta y cumpla con
                cualquier obligación contractual existente.
              </p>
              <p>
                También puede preguntarnos si estamos conservando información
                sobre usted; preguntar cómo se está utilizando su información;
                pedirnos que actualicemos o corrijamos su información; o darse
                de baja de nuestra recopilación y uso de cierta información
                enviando un correo electrónico a support@brigiz.com o
                escribiéndonos a Support, Brigiz, Inc., 803 Lake Avenue, Lake
                Worth, FL 33414, especificando la naturaleza y el alcance de su
                solicitud. Si elige optar por no participar en la recopilación y
                uso de cierta información, comprende que algunos servicios
                pueden dejar de estar disponibles para usted, lo que puede
                requerir la cancelación de su Membresía. Le permitimos impugnar
                los datos que tenemos sobre usted y, cuando corresponda, puede
                tener ciertos datos borrados, rectificados, modificados o
                completados.
              </p>
              <h3>Seguridad, Integridad y Acceso a los Datos</h3>
              <p>
                La Información Personal que proporciona y recopilamos se
                almacena dentro de bases de datos que controlamos directamente o
                a través de nuestros Proveedores de Servicios Administrativos.
                Como consideramos apropiado y según lo exige la ley aplicable,
                utilizamos medidas de seguridad adecuadas para la sensibilidad
                de la Información Personal y consistentes con los estándares de
                la industria, incluidas protecciones físicas, organizativas y
                tecnológicas, como cortafuegos, para proteger su Información
                Personal. También utilizamos tecnología de cifrado estándar de
                la industria para proteger la información de tarjetas de crédito
                y contraseñas. Sin embargo, dado que ningún sistema de seguridad
                es impenetrable, no podemos garantizar la seguridad de nuestras
                bases de datos, ni podemos garantizar que la información que
                proporcione no será interceptada mientras se nos transmite por
                Internet.
              </p>
              <p>
                La Información Personal solo puede ser accedida por personas
                dentro de nuestra organización, nuestros proveedores de
                servicios externos que requieren dicho acceso para brindarle los
                servicios indicados anteriormente, o cualquier otra parte con la
                que nos haya autorizado a compartir dicha información.
              </p>
              <p>
                Retenemos la Información Personal que recopilamos solo mientras
                sea razonablemente necesario para cumplir con los fines para los
                que se recopiló o para cumplir con cualquier requisito legal.
              </p>
              <p>
                Su información puede transferirse a, y mantenerse en,
                computadoras ubicadas fuera de su estado, provincia/territorio o
                país donde las leyes de privacidad pueden no ser tan protectoras
                como las de donde vive. Si se encuentra fuera de los Estados
                Unidos y elige proporcionarnos información, tenga en cuenta que
                transferimos Información Personal a los Estados Unidos y la
                procesamos y almacenamos allí. Como resultado, esta información
                puede estar sujeta a solicitudes de acceso de gobiernos,
                tribunales, funcionarios encargados de hacer cumplir la ley y
                autoridades de seguridad nacional en los Estados Unidos de
                acuerdo con sus leyes. Sujeto a las leyes aplicables,
                utilizaremos esfuerzos razonables para garantizar que se
                mantengan protecciones adecuadas para mantener la protección de
                la Información Personal. Al enviar su Información Personal,
                acepta que sus datos personales se transfieran, procesen y
                almacenen en los Estados Unidos. Brigiz puede asignarle un ID de
                usuario inicial y una contraseña como parte de su participación
                y acceso al sitio web. Su ID de usuario y contraseña solo pueden
                ser utilizados por usted y los miembros de su hogar. No puede
                compartir su ID de usuario y contraseña con nadie más, y es el
                único responsable de mantener y proteger la confidencialidad de
                su ID de usuario y contraseña. Usted es completamente
                responsable de todas las actividades que ocurran bajo su ID de
                usuario y contraseña. Puede acceder y actualizar su información
                de inicio de sesión en su página web "Mis Cuentas".
              </p>
              <h3>Información de Tarjetas de Crédito</h3>
              <p>
                Podemos compartir información de tarjetas de crédito con
                terceros de confianza para facturarle por su membresía.
                Utilizamos terceros para el procesamiento seguro de
                transacciones con tarjetas de crédito, y enviamos información de
                facturación a esos terceros para procesar sus pagos o validar su
                información de tarjeta de crédito en archivo. La información de
                la tarjeta de crédito se cifra durante la transmisión y siempre
                que se almacena dentro de nuestros sistemas o con nuestros
                proveedores. Además de utilizar cifrado que cumple con la
                Certificación FIPS 140-2 del Gobierno de los Estados Unidos, nos
                aseguramos de almacenar solo la cantidad de información
                necesaria para facturarle por servicios.
              </p>
              <p>
                Nuestros proveedores de procesamiento de tarjetas de crédito
                utilizan medidas de seguridad para proteger su información tanto
                durante la transacción como después de completada. Los
                proveedores de procesamiento de tarjetas de crédito están
                certificados como compatibles con múltiples marcos de
                cumplimiento estándar de la industria, incluida la Certificación
                de Proveedor de Servicios de Nivel 1 del PCI, el estándar de
                auditoría SSAE-16.
              </p>
              <h3>Sitios Web y Enlaces de Terceros</h3>
              <p>
                Tenga en cuenta que puede tener cookies colocadas en su
                computadora por sitios web de terceros que lo refieran al sitio
                web. Aunque no compartimos su Información Personal con estos
                sitios web de terceros a menos que nos haya autorizado a
                hacerlo, pueden vincular cierta información no identificable
                personalmente que les transferimos con Información Personal que
                previamente recopilaron de usted. Por favor, revise las
                políticas de privacidad de cada sitio web que visite para
                comprender mejor sus prácticas de privacidad. Además, Brigiz
                desea informarle que cada vez que haga clic en enlaces
                (incluidos banners publicitarios) que lo lleven a sitios web de
                terceros, estará sujeto a las políticas de privacidad de esos
                terceros. Aunque apoyamos la protección de la privacidad de
                nuestros clientes en Internet, Brigiz renuncia expresamente a
                cualquier y toda responsabilidad por las acciones de terceros,
                incluidas, entre otras, las acciones relacionadas con el uso y/o
                divulgación de Información Personal por parte de terceros.
              </p>
              <p>
                Nuestro sitio web puede contener enlaces a otros sitios operados
                por terceros. Brigiz no controla dichos otros sitios y no es
                responsable de su contenido, sus políticas de privacidad o su
                uso de Información Personal. La inclusión de dichos enlaces por
                parte de Brigiz no implica por sí misma ninguna aprobación.
              </p>
              <h3>Privacidad de los Niños</h3>
              <p>
                Brigiz no tiene la intención de que el Sitio Web sea utilizado
                por individuos menores de la mayoría de edad dentro de la
                jurisdicción en la que residen sin la supervisión de un padre o
                tutor mayor de edad. Brigiz confía en dichos padres o tutores
                para determinar si el Sitio Web es apropiado para la
                visualización, acceso o participación de tales individuos
                menores de edad. Si un individuo menor de edad utiliza el Sitio
                Web, dicho individuo solo puede usar el Sitio Web bajo la
                supervisión de un padre o tutor mayor de edad.
              </p>
              <p>
                No buscamos ni recopilamos conscientemente ninguna Información
                Personal sobre niños menores de 13 años de edad. Si nos damos
                cuenta de que hemos recopilado inadvertidamente Información
                Personal de un niño menor de 13 años, haremos esfuerzos
                comercialmente razonables para eliminar dicha información de
                nuestra base de datos.
              </p>
              <p>
                Si usted es el padre o tutor de un menor que nos ha
                proporcionado Información Personal, puede contactarnos para
                solicitar que se elimine. Ley Aplicable; Visitantes que Residen
                Fuera de los Estados Unidos
              </p>
              <p>
                Esta Política de Privacidad y las prácticas de privacidad de
                Brigiz estarán sujetas exclusivamente a las leyes en las que
                Brigiz brinda sus servicios.
              </p>
              <p>
                Puede contactarnos con cualquier pregunta o para conocer las
                categorías de información que podemos divulgar. Consulte a
                continuación: Cómo Contactarnos.
              </p>
              <h3>Cómo Contactarnos</h3>
              <p>
                Todas las solicitudes, preguntas, preocupaciones o quejas sobre
                su Información Personal o esta Política de Privacidad, por favor
                contacte a nuestro Oficial de Privacidad por correo o correo
                electrónico en la siguiente dirección
              </p>
              <p>
                Attn: Privacy Officer, Inc. 803 Lake Avenue Lake Worth, FL 33414
              </p>
              <p>
                Correo electrónico: oficina de privacidad: support@Brigiz.com
              </p>
              <p>Agradecemos sus preguntas y comentarios.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EspPolicy;
